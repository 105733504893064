import AvailabilityCalendarActionTypes from './AvailabilityCalendarActionTypes';
import AvailabilityCalendarInitialState from './AvailabilityCalendarReducerInitialState';

export const AvailabilityCalendarReducer = (state = AvailabilityCalendarInitialState, action = {}) => {
  switch (action.type) {
    case AvailabilityCalendarActionTypes.SET_DATE_FROM:
      return {
        ...state,
        date_from: action.date_from,
      };
    case AvailabilityCalendarActionTypes.SET_DATE_TO:
      return {
        ...state,
        date_to: action.date_to,
      };
    case AvailabilityCalendarActionTypes.SET_DEFAULT_DATE_FROM:
      return {
        ...state,
        default_date_from: action.default_date_from,
      };
    case AvailabilityCalendarActionTypes.SET_DEFAULT_DATE_TO:
      return {
        ...state,
        default_date_to: action.default_date_to,
      };
    case AvailabilityCalendarActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selected_date: action.selected_date,
      };
    case AvailabilityCalendarActionTypes.SET_DATE_FLEX:
      return {
        ...state,
        date_flex: action.date_flex,
      };
    case AvailabilityCalendarActionTypes.SET_DATES:
      return {
        ...state,
        date_from: action.date_from,
        date_to: action.date_to,
        default_date_from: action.default_date_from,
        default_date_to: action.default_date_to,
      };
    case AvailabilityCalendarActionTypes.SET_SEARCH_DATES:
      return {
        ...state,
        date_from: action.date_from,
        date_to: action.date_to,
      };
    case AvailabilityCalendarActionTypes.RESET:
      return AvailabilityCalendarInitialState;
    default:
      return state;
  }
};

export default AvailabilityCalendarReducer;
