import React, {
  useMemo,
  useState,
} from 'react';
import { node } from 'prop-types';
import { ProductContext } from '../../contexts/ProductContext';

export const ProductProvider = ({ children }) => {
  // Store the product selected in state.
  const [selectProduct, setSelectProduct] = useState({
    id: '',
  });

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const productContextValue = useMemo(() => ({
    selectProduct,
    setSelectProduct,
  }), [selectProduct]);

  return (
    <ProductContext.Provider value={productContextValue}>{children}</ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: node.isRequired,
};

ProductProvider.defaultProps = {};

export default ProductProvider;
