const AvailabilityCalendarReducerInitialState = {
  date_from: 'auto',
  date_to: 'auto',
  default_date_from: 'auto',
  default_date_to: 'auto',
  date_flex: false,
  selected_date: '',
};

export default AvailabilityCalendarReducerInitialState;
