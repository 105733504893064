import SearchActionTypes from './SearchActionTypes';
import SearchReducerInitialState from './SearchReducerInitialState';

export const SearchReducer = (state = SearchReducerInitialState, action = {}) => {
  switch (action.type) {
    case SearchActionTypes.SET_ATTRACTION_ID:
      return {
        ...state,
        attraction_id: action.attraction_id,
      };
    case SearchActionTypes.SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case SearchActionTypes.SET_SALE_CUR:
      return {
        ...state,
        sale_cur: action.sale_cur,
      };
    case SearchActionTypes.RESET:
      return SearchReducerInitialState;
    default:
      return state;
  }
};

export default SearchReducer;
