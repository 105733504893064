import React, {
  useMemo,
  useState,
  useReducer,
  useEffect,
} from 'react';
import { node } from 'prop-types';
import { EnvironmentContext } from '../../contexts/EnvironmentContext';
import { EnvironmentReducer } from '../../reducers/EnvironmentReducer';
import EnvironmentActionTypes from '../../reducers/EnvironmentReducer/EnvironmentActionTypes';
import EnvironmentReducerInitialState from '../../reducers/EnvironmentReducer/EnvironmentReducerInitialState';

export const EnvironmentProvider = ({ children }) => {
  // Store the api session in state.
  const [apiHeaders, setApiHeaders] = useState({
    headers: {},
  });

  // Store environmental variables in state.
  const [appEnv, setAppEnv] = useReducer(
    EnvironmentReducer,
    EnvironmentReducerInitialState,
  );

  // Set up the environment variables.
  useEffect(() => {
    // Store the required API headers in state for the various
    // components to use.
    try {
      const apiSid = localStorage.getItem('apiSid');
      if (apiSid) {
        setApiHeaders({
          ...apiHeaders,
          headers: {
            Authorization: `Basic ${apiSid}`,
          },
        });
      }
    } catch (e) {
      /* eslint-disable no-debugger, no-console */
      console.error(`The API session id was not found: ${e.message}`);
      /* eslint-enable no-alert, no-console */
    }

    // Set the inital app view in state if available.
    const appView = (typeof drupalSettings !== 'undefined' && drupalSettings.assistedNavApp !== 'undefined' && drupalSettings.assistedNavApp.app_view !== 'undefined') ? drupalSettings.assistedNavApp.app_view : '';
    if (appView && appView !== appEnv.app_view) {
      setAppEnv({
        type: EnvironmentActionTypes.SET_APP_VIEW,
        app_view: appView,
      });
    }

    // Set the inital app type in state if available.
    const appType = (typeof drupalSettings !== 'undefined' && drupalSettings.assistedNavApp !== 'undefined' && drupalSettings.assistedNavApp.app_type !== 'undefined') ? drupalSettings.assistedNavApp.app_type : '';
    if (appType && appType !== appEnv.app_type) {
      setAppEnv({
        type: EnvironmentActionTypes.SET_APP_TYPE,
        app_type: appType,
      });
    }
  }, []);

  /**
   * Set up the app env state.
   */
  useEffect(() => {
    if (typeof window.backboneAppReady !== 'undefined' && window.backboneAppReady === true) {
      // Save that we are accessing the app via the order edit app.
      if (appEnv.env !== 'orderEdit') {
        setAppEnv({
          type: EnvironmentActionTypes.SET_ENV,
          env: 'orderEdit',
        });
      }
    } else if (typeof window.assistedNavAppEnv !== 'undefined') {
      // Save that we are accessing the app via the order edit app.
      if (appEnv.env !== window.assistedNavAppEnv) {
        setAppEnv({
          type: EnvironmentActionTypes.SET_ENV,
          env: window.assistedNavAppEnv,
        });
      }
    }
  }, [window.backboneAppReady, window.assistedNavAppEnv]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const envContextValue = useMemo(() => ({
    apiHeaders,
    appEnv,
    setApiHeaders,
    setAppEnv,
  }), [apiHeaders, appEnv]);

  return (
    <EnvironmentContext.Provider value={envContextValue}>{children}</EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = {
  children: node.isRequired,
};

EnvironmentProvider.defaultProps = {};

export default EnvironmentProvider;
