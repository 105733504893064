import React from 'react';
import { useSearchContext } from '../../hooks/useSearchContext';
import SearchActionTypes from '../../reducers/SearchReducer/SearchActionTypes';
import styles from './SearchFilters.module.css';

export const SearchFilters = () => {
  const hideInputs = (process.env.NODE_ENV !== 'development');

  // Get the search state.
  const { search, setSearch } = useSearchContext();

  // Get the available languages for the app.
  const availableLanguages = (process.env.REACT_APP_AVAILABLE_LANGUAGES) ? process.env.REACT_APP_AVAILABLE_LANGUAGES.split(' ') : ['en', 'de'];

  return (
    <div>
      { !hideInputs && <p>The search filter inputs are displaying because the app is in development mode.</p> }
      <input
        type="number"
        disabled={!!hideInputs}
        className={`assisted-nav-ticket-attraction-id ${hideInputs ? styles.attractionId : ''}`}
        onChange={(e) => {
          if (e.target.value !== search.attraction_id) {
            setSearch({
              type: SearchActionTypes.SET_ATTRACTION_ID,
              attraction_id: e.target.value,
            });
          }
        }}
        value={search.attraction_id}
      />
      <select
        disabled={!!hideInputs}
        className={`assisted-nav-ticket-lang ${hideInputs ? styles.lang : ''}`}
        onChange={(e) => {
          if (e.target.value !== search.lang) {
            setSearch({
              type: SearchActionTypes.SET_LANG,
              lang: e.target.value,
            });
          }
        }}
        value={search.lang}
      >
        { availableLanguages.map((language) => <option key={language} value={language}>{language}</option>) }
      </select>
    </div>

  );
};

export default SearchFilters;
