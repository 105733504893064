import React, {
  useMemo,
  useState,
} from 'react';
import { node } from 'prop-types';
import { NumDaysContext } from '../../contexts/NumDaysContext';

export const NumDaysProvider = ({ children }) => {
  // Store the number of days selected in state.
  const [selectNumDays, setSelectNumDays] = useState({
    num_days: 0,
  });

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const selectNumDaysContextValue = useMemo(() => ({
    selectNumDays,
    setSelectNumDays,
  }), [selectNumDays]);

  return (
    <NumDaysContext.Provider value={selectNumDaysContextValue}>{children}</NumDaysContext.Provider>
  );
};

NumDaysProvider.propTypes = {
  children: node.isRequired,
};

NumDaysProvider.defaultProps = {};

export default NumDaysProvider;
