import React, {
  useMemo,
  useEffect,
  useReducer,
} from 'react';
import { node } from 'prop-types';
import { AvailabilityCalendarContext } from '../../contexts/AvailabilityCalendarContext';
import { useHotelContext } from '../../hooks/useHotelContext';
import { AvailabilityCalendarReducer } from '../../reducers/AvailabilityCalendarReducer';
import AvailabilityCalendarActionTypes from '../../reducers/AvailabilityCalendarReducer/AvailabilityCalendarActionTypes';
import AvailabilityCalendarReducerInitialState from '../../reducers/AvailabilityCalendarReducer/AvailabilityCalendarReducerInitialState';
import { getStartOfMonth, getNextDateTo, getAddMonths } from '../../utils/availabilityCalendar';

export const AvailabilityCalendarProvider = ({ children }) => {
  // Get the hotel search state.
  const { hotelSearch } = useHotelContext();

  // Store the availability calendar information in state.
  const [availabilityCalendar, setAvailabilityCalendar] = useReducer(
    AvailabilityCalendarReducer,
    AvailabilityCalendarReducerInitialState,
  );

  // Update the availability calendar state if the hotel search state is updated.
  useEffect(() => {
    // If the app is being used as part of the hotels checkout, we can
    // pre-populate the initial availability calendar so that it initially
    // displays dates relevant to the stay.
    if (hotelSearch && typeof hotelSearch.date_from !== 'undefined') {
      const addMonths = getAddMonths();
      const dateFrom = getStartOfMonth(hotelSearch.date_from);
      const dateTo = getNextDateTo(dateFrom, addMonths);
      setAvailabilityCalendar({
        type: AvailabilityCalendarActionTypes.SET_DATES,
        date_from: dateFrom,
        date_to: dateTo,
        default_date_from: dateFrom,
        default_date_to: dateTo,
      });
    }
  }, [hotelSearch]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const availabilityCalendarContextValue = useMemo(() => ({
    availabilityCalendar,
    setAvailabilityCalendar,
  }), [availabilityCalendar]);

  return (
    <AvailabilityCalendarContext.Provider value={availabilityCalendarContextValue}>{children}</AvailabilityCalendarContext.Provider>
  );
};

AvailabilityCalendarProvider.propTypes = {
  children: node.isRequired,
};

AvailabilityCalendarProvider.defaultProps = {};

export default AvailabilityCalendarProvider;
