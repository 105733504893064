import React, {
  useEffect,
  useState,
} from 'react';
import { node } from 'prop-types';
import { ABContext } from '../../contexts/ABContext';
import isABTest from '../../ab/isabtest';

export const ABContextProvider = ({ children }) => {
  const [abTests, setABTests] = useState({});

  useEffect(() => {
    const HOT547IsVariant = isABTest('HOT-547-dlp-hotels--build-your-own--tickets-redesign-v2');

    // Add context
    const testHOT547 = ({
      HOT547: {
        name: 'HOT-547-dlp-hotels--build-your-own--tickets-redesign-v2',
        variant: HOT547IsVariant,
      },
    });

    // DGS-2373-New-green-shade-AB-test
    const DGS2373Variant = isABTest('DGS-2373-New-green-shade-AB-test');

    // Add context
    const testDGS2373 = ({
      DGS2373: {
        name: 'DGS-2373-New-green-shade-AB-test',
        variant: DGS2373Variant,
      },
    });

    // Update state
    setABTests((prevState) => ({ ...prevState, ...testHOT547, ...testDGS2373 }));
  }, []);

  return (
    <ABContext.Provider value={abTests}>{ children }</ABContext.Provider>
  );

  // CRO-000-test = example cro test
  // useEffect(() => {
  //   const CRO000IsVariant = isABTest('CRO-000-test-name');

  //   // Add context
  //   const testCRO000 = ({
  //     CRO000: {
  //       name: 'CRO-000-test-name',
  //       variant: CRO000IsVariant,
  //     },
  //   });

  //   // Update state
  //   setABTests((prevState) => ({ ...prevState, ...testCRO000 }));
  // }, []);

  // return (
  //   <ABContext.Provider value={abTests}>{ children }</ABContext.Provider>
  // );
  // End CRO-000-test = example cro test
};

ABContextProvider.propTypes = {
  children: node.isRequired,
};

ABContextProvider.defaultProps = {};

export default ABContextProvider;
