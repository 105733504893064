import React, {
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { node } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchContext } from '../../contexts/SearchContext';
import { SearchReducer } from '../../reducers/SearchReducer';
import SearchActionTypes from '../../reducers/SearchReducer/SearchActionTypes';
import SearchReducerInitialState from '../../reducers/SearchReducer/SearchReducerInitialState';

export const SearchProvider = ({ children }) => {
  // Store search in state.
  const [search, setSearch] = useReducer(
    SearchReducer,
    SearchReducerInitialState,
  );

  /**
   * Set up the initial search state.
   */
  useEffect(() => {
    // Store the Drupal lang in state if available.
    const initialLang = (typeof drupalSettings !== 'undefined' && drupalSettings.path.currentLanguage !== 'undefined') ? drupalSettings.path.currentLanguage : process.env.REACT_APP_DEFAULT_LANG;
    if (initialLang && initialLang !== search.lang) {
      setSearch({
        type: SearchActionTypes.SET_LANG,
        lang: initialLang,
      });
    }

    // Set the inital attraction id in state if available.
    const attractionId = (typeof drupalSettings !== 'undefined' && drupalSettings.assistedNavApp !== 'undefined' && drupalSettings.assistedNavApp.app_id !== 'undefined') ? drupalSettings.assistedNavApp.app_id : '';
    if (attractionId && attractionId !== search.attraction_id) {
      setSearch({
        type: SearchActionTypes.SET_ATTRACTION_ID,
        attraction_id: attractionId,
      });
    }
  }, []);

  /**
  * Change the app language if the lang is updated.
  * If we change the language, at the moment, that would
  * also indicate a change of currency.
  */
  const { i18n } = useTranslation('common');
  useEffect(() => {
    // Check if the language in search state matches with the current
    // app language. If not, change it.
    if (i18n.language !== search.lang) {
      // Update the language.
      i18n.changeLanguage(search.lang);
    }
  }, [search]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const searchContextValue = useMemo(() => ({
    search,
    setSearch,
  }), [search]);

  return (
    <SearchContext.Provider value={searchContextValue}>{children}</SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: node.isRequired,
};

SearchProvider.defaultProps = {};

export default SearchProvider;
