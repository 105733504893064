const AvailabilityCalendarActionTypes = {
  SET_DATE_FROM: 'SET_DATE_FROM',
  SET_DATE_TO: 'SET_DATE_TO',
  SET_DEFAULT_DATE_FROM: 'SET_DEFAULT_DATE_FROM',
  SET_DEFAULT_DATE_TO: 'SET_DEFAULT_DATE_TO',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_DATE_FLEX: 'SET_DATE_FLEX',
  SET_DATES: 'SET_DATES',
  SET_SEARCH_DATES: 'SET_SEARCH_DATES',
  RESET: 'RESET',
};

export default AvailabilityCalendarActionTypes;
