import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import formatPrice from './price';

/**
 * Return the number of months to display in a calendar.
 */
export const getAddMonths = () => {
  const addMonths = 1;
  return addMonths;
};

/**
 * Format a given date and use current app lang.
 *
 * @param {*} date
 * @param {*} lang
 * @returns
 */
export const formatDate = (date, lang) => {
  dayjs.locale(lang);
  const formattedDate = dayjs(date).format('MMMM D, YYYY');
  return formattedDate;
};

/**
 * Get the first date of the month for a given date.
 *
 * @param {*} date
 * @returns
 */
export const getStartOfMonth = (date) => {
  const newDate = dayjs(date).startOf('month').format('YYYY-MM-DD');
  return newDate;
};

/**
 * Get the next date from based on the current date search and the number of months to add
 *
 * @param {*} dateFrom
 * @param {*} dateTo
 * @param {*} addMonths
 * @param {*} lastDate
 */
export const getNextDateFrom = (dateFrom, addMonths) => {
  let newDateFrom = dateFrom;
  if (newDateFrom === 'auto') {
    newDateFrom = dayjs().format('YYYY-MM-DD');
  }

  // Set to the first day of current from date + x number of months.
  newDateFrom = dayjs(newDateFrom).add(addMonths, 'month').startOf('month').format('YYYY-MM-DD');

  return newDateFrom;
};

/**
 * Get the next date from based on the current date search and the number of months to subtract
 *
 * @param {*} dateFrom
 * @param {*} dateTo
 * @param {*} addMonths
 * @param {*} lastDate
 */
export const getPrevDateFrom = (dateFrom, addMonths) => {
  let newDateFrom = dateFrom;
  if (newDateFrom === 'auto') {
    newDateFrom = dayjs().format('YYYY-MM-DD');
  }

  // Set to the first day of current from date - x number of months.
  newDateFrom = dayjs(newDateFrom).add(-Math.abs(addMonths), 'month').startOf('month').format('YYYY-MM-DD');

  return newDateFrom;
};

/**
 * Get the next date to based on a from date yyyy-mm-dd and the number of months to add.
 *
 * @param {*} dateFrom
 * @param {*} addMonths
 */
export const getNextDateTo = (dateFrom, addMonths) => {
  // Convert the date from into a dayjs object.
  const dateFromObj = dayjs(dateFrom);

  // Increase the date to by x number of months after the from date
  // and then get the last day of the month.
  // If we are only adding a single month, we only need to get to
  // the end of the date from month.
  let dateTo = '';
  if (addMonths > 0) {
    dateTo = dateFromObj
      .add(addMonths, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
  } else {
    dateTo = dateFromObj
      .endOf('month')
      .format('YYYY-MM-DD');
  }

  return dateTo;
};

/**
 * Go through the availability and find the first
 * available date after today.
 *
 * @param {*} productItem
 */
export const getStartDate = (dateFrom) => {
  let startDate = dateFrom;
  if (startDate === 'auto') {
    const today = dayjs().format('YYYY-MM-DD');
    startDate = today;
  }
  return startDate;
};

/**
 * Get the class related to the season type for a given date
 * i.e. high, mid, low etc...
 *
 * @param {*} productsResponse
 * @param {*} date
 * @returns
 */
export const getDateClass = (productsResponse, date, today = null) => {
  let className = '';
  if (
    typeof productsResponse !== 'undefined'
    && productsResponse.length > 0
  ) {
    const isDateAvailable = getIsDateAvailableProducts(productsResponse, date, today);
    if (isDateAvailable) {
      productsResponse.forEach((productResponse) => {
        if (
          typeof className !== 'undefined'
          && className.length <= 0
          && typeof productResponse.data !== 'undefined'
          && typeof productResponse.data.tickets !== 'undefined'
          && productResponse.data.tickets.length > 0
        ) {
          productResponse.data.tickets.forEach((ticket) => {
            if (
              typeof className !== 'undefined'
              && className.length <= 0
              && typeof ticket.availability !== 'undefined'
            ) {
              ticket.availability.forEach((availability) => {
                if (
                  typeof className !== 'undefined'
                  && className.length <= 0
                  && date === availability.date
                  && typeof availability.class !== 'undefined'
                  && availability.class.length > 0
                ) {
                  className = availability.class.toString();
                }
              });
            }
          });
        }
      });
    }
  }

  return className;
};

/**
 * Get the price related to the season type for a given date
 * i.e. high, mid, low etc... (This is a copy of above function with price instead of class being accessed)
 *
 * @param {*} productsResponse
 * @param {*} date
 * @param {*} searchContext
 * This will have lang and currency for the price
 * @returns
 */
export const getPriceFromAmount = (productsResponse, date, searchContext, today = null) => {
  let className = '';
  if (
    typeof productsResponse !== 'undefined'
    && productsResponse.length > 0
  ) {
    const isDateAvailable = getIsDateAvailableProducts(productsResponse, date, today);
    if (isDateAvailable) {
      productsResponse.forEach((productResponse) => {
        if (
          typeof className !== 'undefined'
          && className.length <= 0
          && typeof productResponse.data !== 'undefined'
          && typeof productResponse.data.tickets !== 'undefined'
          && productResponse.data.tickets.length > 0
        ) {
          productResponse.data.tickets.forEach((ticket) => {
            if (
              typeof className !== 'undefined'
              && className.length <= 0
              && typeof ticket.availability !== 'undefined'
            ) {
              ticket.availability.forEach((availability) => {
                if (
                  typeof className !== 'undefined'
                  && className.length <= 0
                  && date === availability.date
                  && typeof availability.class !== 'undefined'
                  && availability.class.length > 0
                ) {
                  className = formatPrice(availability.price, searchContext);
                }
              });
            }
          });
        }
      });
    }
  }

  return className;
};

/** Format a class name */
export const formatDateClass = (className) => className.replace(/_/g, '-').toLowerCase();

/**
 * Check if a provided date is in tickets availability for a collection of products.
 *
 * @param {*} productItem
 * @param {*} date
 */
export const getIsDateAvailableProducts = (productsResponse, date, today = null) => {
  let dateAvailable = false;
  let now = today;
  if (
    typeof now === 'undefined'
    || now === null
  ) {
    now = dayjs().format('YYYY-MM-DD');
  }

  if (
    typeof productsResponse !== 'undefined'
    && productsResponse.length > 0
  ) {
    productsResponse.forEach((productResponse) => {
      if (
        dateAvailable === false
        && typeof productResponse !== 'undefined'
        && typeof productResponse.data !== 'undefined'
        && productResponse.isSuccess
        && Number.parseInt(productResponse.data.calendar_based_pricing, 10) === 1
        && typeof productResponse.data.tickets !== 'undefined'
        && productResponse.data.tickets.length > 0
      ) {
        productResponse.data.tickets.forEach((ticket) => {
          if (
            dateAvailable === false
            && typeof ticket.availability !== 'undefined'
          ) {
            ticket.availability.forEach((availability) => {
              if (
                dateAvailable === false
                && date === availability.date && date >= now
              ) {
                dateAvailable = true;
              }
            });
          }
        });
      }
    });
  }

  return dateAvailable;
};

/**
 * Check if a provided date is in tickets availability for a product.
 *
 * @param {*} productItem
 * @param {*} date
 */
export const getIsDateAvailableProduct = (productResponse, date, today = null) => {
  let dateAvailable = false;
  let now = today;
  if (
    typeof now === 'undefined'
    || now === null
  ) {
    now = dayjs().format('YYYY-MM-DD');
  }

  if (
    dateAvailable === false
    && typeof productResponse.data.tickets !== 'undefined'
  ) {
    productResponse.data.tickets.forEach((ticket) => {
      if (
        dateAvailable === false
        && typeof ticket.availability !== 'undefined'
      ) {
        ticket.availability.forEach((availability) => {
          if (
            dateAvailable === false
            && date === availability.date && date >= now
          ) {
            dateAvailable = true;
          }
        });
      }
    });
  }

  return dateAvailable;
};
