import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { node } from 'prop-types';
import { HotelContext } from '../../contexts/HotelContext';
import { useEnvironmentContext } from '../../hooks/useEnvironmentContext';

export const HotelProvider = ({ children }) => {
  // Get the app environment state.
  const { appEnv } = useEnvironmentContext();

  // Store the hotel search via local storage in state.
  const [hotelSearch, setHotelSearch] = useState();

  useEffect(() => {
    // If the app is being used as part of the hotels checkout, we can
    // pre-populate the initial availability calendar so that it initially
    // displays dates relevant to the stay.
    if (appEnv.app_type === 'room-only') {
      const lsHotelSearch = localStorage.getItem('hotel_search');
      if (lsHotelSearch) {
        const params = JSON.parse(lsHotelSearch);
        if (
          typeof params !== 'undefined'
          && typeof params.date_from !== 'undefined'
        ) {
          setHotelSearch(params);
        }
      }
    }
  }, [appEnv]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const hotelContextValue = useMemo(() => ({
    hotelSearch,
  }), [hotelSearch]);

  return (
    <HotelContext.Provider value={hotelContextValue}>{children}</HotelContext.Provider>
  );
};

HotelProvider.propTypes = {
  children: node.isRequired,
};

HotelProvider.defaultProps = {};

export default HotelProvider;
