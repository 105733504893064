import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { node } from 'prop-types';
import { TicketTypesContext } from '../../contexts/TicketTypesContext';
import { useHotelContext } from '../../hooks/useHotelContext';

export const TicketTypesProvider = ({ children }) => {
  // Get the hotel search state.
  const { hotelSearch } = useHotelContext();

  // Store the selected qtys for each type in state.
  const [selectTicketTypes, setSelectTicketTypes] = useState([
    {
      type: 'Adult',
      qty: 0,
    },
    {
      type: 'Child',
      qty: 0,
    },
  ]);

  // Update the selectedTicketTypes state if the hotel search state is updated.
  useEffect(() => {
    // If the app is being used as part of the hotels checkout, we can
    // pre-populate the initial ticket type quantities so that it initially
    // displays the quantities relevant to the stay.
    if (hotelSearch && typeof hotelSearch.date_from !== 'undefined') {
      // Go through the hotel search object and accumulate the room
      // occupancy quantities to work out the ticket type quantities.
      let adultQty = 0;
      let childQty = 0;
      let childAges = [];
      Object.entries(hotelSearch).forEach(([hotelParam, hotelVal]) => {
        if (hotelParam.includes('adults')) {
          adultQty += Number.parseInt(hotelVal, 10);
        } else if (hotelParam.includes('children')) {
          childQty += Number.parseInt(hotelVal, 10);
        } else if (hotelParam.includes('child_ages')) {
          childAges = childAges.concat(hotelVal.split(','));
        }
      });

      // An adult is a person over 12.
      // todo - Get this information from an API request so that it is not hardcoded.
      childAges.forEach((age) => {
        if (age > 11) {
          adultQty += 1;
          childQty -= 1;
        }
      });

      setSelectTicketTypes([
        {
          type: 'Adult',
          qty: adultQty ? Number.parseInt(adultQty, 10) : 0,
        },
        {
          type: 'Child',
          qty: childQty ? Number.parseInt(childQty, 10) : 0,
        },
      ]);
    }
  }, [hotelSearch]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const selectTicketTypesContextValue = useMemo(() => ({
    selectTicketTypes,
    setSelectTicketTypes,
  }), [selectTicketTypes]);

  return (
    <TicketTypesContext.Provider value={selectTicketTypesContextValue}>{children}</TicketTypesContext.Provider>
  );
};

TicketTypesProvider.propTypes = {
  children: node.isRequired,
};

TicketTypesProvider.defaultProps = {};

export default TicketTypesProvider;
