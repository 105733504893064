import EnvironmentActionTypes from './EnvironmentActionTypes';
import EnvironmentReducerInitialState from './EnvironmentReducerInitialState';

export const EnvironmentReducer = (state = EnvironmentReducerInitialState, action = {}) => {
  switch (action.type) {
    case EnvironmentActionTypes.SET_ENV:
      return {
        ...state,
        env: action.env,
      };
    case EnvironmentActionTypes.SET_APP_VIEW:
      return {
        ...state,
        app_view: action.app_view,
      };
    case EnvironmentActionTypes.SET_APP_TYPE:
      return {
        ...state,
        app_type: action.app_type,
      };
    case EnvironmentActionTypes.RESET:
      return EnvironmentReducerInitialState;
    default:
      return state;
  }
};

export default EnvironmentReducer;
